@import url("https://cdn.syncfusion.com/ej2/material.css");
.section_choices{
    width: 100%;
    background-color: #181818;
    height: auto;
}
.form{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: auto;
}
.form-container{
    letter-spacing: 1px;
    margin: 30px 0px;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.form-container header h2{
    position: relative;
    padding: 10px 20px;
    color: #09bef0;
    font-weight: 500;
    margin-bottom: 5px;
    font-size: 30px;
}
.office{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: auto;
    background-color: #121b22;
}
.office-containers{
    letter-spacing: 1px;
    margin: 30px 0px;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
form{
    width: auto;
    display: block;
    margin-left: 3px;
}
.ui-message-success{
    font-size: 20px;
    color: #ffffff;
}
form input{
    width: 250px;
    height: 30px;
    margin-bottom: 5px;
    border-radius: 6px;
    border-color: #1ca2e3;
}
form select{
    width: 250px;
    height: 30px;
    margin-bottom: 5px;
    border-radius: 6px;
    border-color: #1ca2e3; 
}
.icon label p{
    font-size: 13px;
    margin-left: 30px;
    color: #ffffff;
    margin-bottom: 5px;
    margin-top: 15px;
    font-weight: 500;
}
.icon p{
    margin-top: 0.1rem;
    font-size: 0.7rem;
    color: #ed5959;
}
.too span{
    display: flex;
}
.icon_move{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.icon_move label{
    font-size: 13px;
    color: #ffffff;
    margin-bottom: 3px;
    margin-top: 15px;
    font-weight: 500;
}
.icon2 p{
    font-size: 15px;
    color: #ffffff;
    margin-left: 10px;
    margin-top: 15px;
    font-weight: 500; 
}
.error{
    font-size: 11px;
    color: #f21010;
    margin-bottom: 3px;
    margin-top: 2px;
    font-weight: 500; 
}
form p{
    font-size: 10px;
    margin-left: 30px;
    color: #ffffff;
    margin-bottom: 5px;
    font-weight: 500;
}
form button{
    background-color: #1ca2e3; /* Green */
    border: none;
    color: white;
    padding: 10px 52px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-left: 50px;
    border-radius: 5px;
}
.more{
    margin-top: 5px;
}

.ui-message-success-2{
    color:  #1ca2e3;
}
.grid{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    grid-gap: 4rem;
    gap: 0.7rem  ; 
    margin-top: 20px;
}
@media (width < 450px) {
    .grid{
        display: flex;
        flex-direction: column;
        grid-gap: 0.8rem;
        margin-left: 40px;
    }
    .my_choice-more{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2rem;
        grid-gap: 1rem;
        gap: 0.7rem  ;
        margin-top: 20px;
    }
    .section_choice {
        height: auto;
    }
    form button {
        padding: 6px 16px;
        font-size: 16px;
        margin-bottom: 20px;
        border-radius: 5px;
    }
  }
  