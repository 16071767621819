@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap");

:root {
  --primary-color: #1371C3;
  --text-dark: #242424;
  --text-light: #e2e8f0;
  --white: #ffffff;
  --max-width: 1200px;
}
body{
  overflow-x: hidden;
}
/*header*/
header{
  background: url(C:/Users/user1/Documents/movers/frontend/src/img/wallpaper.jpg);
  height: 650px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.section{
  max-width: 100%;
  margin: auto;
  padding: 1rem 1rem;
  height:600px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
.text{
  font-size: 4rem;
  text-align: center;
  margin-top: 1rem;
  color: #ffffff;
}
.text-small{
  font-size: 2rem;
  text-align: center;
  margin-top: 0.5rem;
  color: #ffffff;
}
.left{
  position: relative;
}
.right{
  height: 100%;
  position: relative;
  margin-left: 50px;
}

.img1{
  width:250px;
  position: absolute;
  top:50px;
}
.img2{
  width: 280px;
  position: absolute;
  top:150px;
  left:100px;
  height: 280px;
}
.explain-product{
  width: 500px;
  margin:auto;
  padding-top:1rem;
}
.explain-product p{
  text-align: justify;
  color: #ffffff;
  font-size: 1rem;
}
.learn{
  margin-top: 20px;
}
.learn a {
  color: #1ca2e3;
  text-decoration: none;
  position: relative;
}
.learn a::before{
  content: "";
  position: absolute;
  bottom:  -2px;
  height: 2px;
  width: 100%;
  background: #1ca2e3;
  border-radius: 50px;
  transform: scaleX(0);
  transition: transform 0.2 linear;

}
.learn a:hover::before{
  transform: scaleX(1);
}
.star-square{
  display: none;
  position: absolute;
  bottom:40px;
  padding-top:1rem;
}
.star-square img{
  width:80px;
  margin: 10px;
}

.button{
  width:80%;
  margin-left: auto;
  margin-top: 1rem;
}
.btn-order{
  background-color: #1ca2e3; /* Green */
  border: none;
  color: white;
  padding: 10px 19px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
  cursor:pointer;
}
.section__container {
    max-width: var(--max-width);
    margin: auto;
    padding: 3rem 1.5rem;
  }
  
  .section__header {
    font-weight: 600;
    line-height: 2rem;
    margin-bottom: 2rem;
    font-size: 2.7em;
    font-family: 'myriad-bold';
    color: #474646;
  }
  
  strong{
    color:  var(--primary-color);
    margin-bottom: 2rem;
  }
  img {
    width: 100%;
  }
  
  a {
    text-decoration: none;
  }
  
  body {
    font-family: "Poppins", sans-serif;
  }
  .benefits__container {
   max-width: 100%;
   background-color: #ffffff;
  }
  /* .benefits__card:hover {
    margin-bottom: 10px;
   } */
    
  .benefits__container .section__header {
    max-width: 400px;
    margin-bottom: 2rem;
    margin-left: 5rem;
    position: relative;
  }
  
  .benefits__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    border: 2px solid transparent;
  }
  
  .benefits__card {
    padding: 1rem;
    display: block;
    margin-left: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 15%);
  }
  
  .benefits__card span img{
    width: 60px;
    height: 60px;
    border-radius: 100%;
    margin-left: 40px;
    margin-bottom: 10px;
  }
.benefits__card span{
  color: #2d9bea;
  margin-left: 80px;
}
  .benefits__card h4 {
    font-size: 1rem;
    font-weight: 600;
    color: var(--text-dark);
    margin-bottom: 20px;
    margin-left: 50px;
  }
  
  .benefits__content p {
    font-size: 0.9rem;
    line-height: 1.75rem;
    color: #000;
  }
  
  .experience__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
  .experience__description p {
    margin-bottom: 0.8rem;
    font-size: 0.9rem;
    color: #000;
  }
  .experience__image img {
    max-width: 400px;
    height: 400px;
    object-fit: cover;
    border-radius: 5px;
    margin: 50px;
  
  }
  .experience__images img {
    max-width: 400px;
    height: 400px;
    object-fit: cover cover;
    border-radius: 5px;
    margin: 50px;
  
  }
  
  .experience__description {
    margin-top: 0.9 rem;
  font-size: 0.9rem;
  line-height: 1.75rem;
  color: #000;
  
  }
  
  .experience__features {
    margin: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 3rem;
  }
  
  .experience__col h3 {
    font-size: 3rem;
    font-weight: 600;
    color: var(--primary-color);
  }
  
  .experience__col p {
    font-size: 1rem;
    font-weight: 500;
    color: #000;
    
  }
  
  .experience__content button {
    padding: 0.75rem 1.5rem;
    font-size: 0.9rem;
    font-weight: 600;
    color: var(--primary-color);
    outline: none;
    border: 2px solid var(--primary-color);
    background-color: var(--white);
    cursor: pointer;
    transition: 0.3s;
  }
  
  .experience__content button:hover {
    color: var(--white);
    background-color: var(--primary-color);
  }
  
  .process__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
  
  .process__grid {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 50px auto;
    column-gap: 1.5rem;
    row-gap: 0.5rem;
    align-items: center;
    
    
  }
  
  .process__grid .number {
    height: 50px;
    width: 50px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: 600;
    color: var(--white);
    background-color: var(--primary-color);
  }
  
  .process__grid h4 {
    font-size: 1.2rem;
    font-weight: 600;
    color: #474646;
  }
  
  .process__grid p {
    margin-top: 0.5rem;
    font-size: 0.9rem;
    color: #000;
  }
  
  .process__grid span:not(.last-child) {
    height: 100%;
    width: 2px;
    margin: auto;
    background-color: var(--primary-color);
  }
  
  .process__image {
    text-align: right;
  }
  
  .process__image img {
    max-width: 400px;
    height: 400px;
    border-radius: 5px;
    margin-top: 50px;
  }
  
  .customer__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
  }
  
  .customer__nav .section__header {
    max-width: 400px;
  }
  
  .customer__nav__icons {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .customer__nav__icons span {
    font-size: 1.5rem;
    color: var(--primary-color);
    cursor: pointer;
  }
  
  .customer__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
  
  .customer__card {
    padding: 2rem;
    display: grid;
    place-content: baseline;
    gap: 1rem;
    color: var(--white);
    border-radius: 5px;
    background-color: var(--primary-color);;
  }
  
  .customer__card span i {
    font-size: 4rem;
  }
  
  .customer__title {
    font-size: 1.5rem;
    font-weight: 500;
  }
  
  .customer__description {
    margin-bottom: 1rem;
    font-size: 1rem;
    color: #ffffff;
  }
  
  .customer__content {
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }
  
  .customer__content span img{
    display: flex;
    max-width: 50px;
    height: 50px;
    border-radius: 100%;

  }
  
  .customer__details h4 {
    font-size: 1rem;
    font-weight: 500;
  }
  
  .customer__details p {
    font-size: 0.9rem;
    color: var(--text-light);
  }
  .all-review{
    width: 25vw;
    margin-top: 30px;
  }
  
  .image_wrapper {
    margin: 0px 30px;
  }

  .carousel{
    width: 60vw;
    padding: 1.5rem;
    background-color: #fff;
    color: #fff;
    margin-top: 50px;
  }
  .carousel .box{
   height: 150px;
   background-color: transparent;
  
  }
  .slick-slide div{
    margin: 0 1rem;
  }
  .slick-dots li button {
   
    width: 50px;
    height: 50px;
    padding: 5px;
    cursor: pointer;
    color: #fff  !important;
    background: transparent;
}
  .slick-dots li.slick-active button:before {
    opacity: 1;
    color: #1ca2e3 !important;
}
.slick-dots li button:before {
 color: var(--primary-color) !important;
 font-size: 1rem !important;
 top: 0.5rem !important;
}
  .image_wrapper img {
    width: 80%;
  }

.slick-next {
   
    width: 40px !important;
    height: 40px !important;
    cursor: pointer;
    background: var(--primary-color) !important;
}
.slick-prev{
    width: 40px !important;
    height: 40px !important;
    cursor: pointer;
    background: var(--primary-color) !important;
}




  @media (width < 1000px) {
    .benefits__grid {
      gap: 1rem;
    }
    .right{
      display: none;
    }
    .benefits__card {
      flex-direction: column;
    }
  
    .customer__grid {
      gap: 1rem;
    }
  
    .footer__container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (width < 925px) {
    .nav__links {
      display: none;
    }
    .text{
      font-size: 3rem;
    }
  
    .header__ribbon {
      background-color: rgba(92, 108, 121, 0.7);
    }
    .right{
      display: none;
    }
    .arrow__down {
      display: none;
    }
  
    .benefits__container .section__header {
      margin-left: 1rem;
    }
  
    .benefits__grid {
      grid-template-columns: repeat(1, 1fr);
    }
  
    .benefits__card {
      flex-direction: row;
    }
  
    .experience__container {
      grid-template-columns: repeat(1, 1fr);
      margin: 5px;
    }
  
    .process__container {
      grid-template-columns: repeat(1, 1fr);
    }
  
    .process__image {
      grid-area: 1/1/2/2;
    }
  
    .customer__grid {
      grid-template-columns: repeat(1, 1fr);
      gap: 1rem;
    }
  
    .footer__container {
      gap: 2rem;
    }
  }

  
  @media (width < 450px) {
    .experience__image img {
      max-width: 300px;
      margin: 5px;
  }
  .experience__images img {
    max-width: 300px;
    margin: 5px;
}
  .experience__description {
   max-width: 350px;
   margin-right: 20px;
   font-size: 0.9rem;
  }
  .all-review {
    width: 60vw;
    margin-top: 30px;
}
  .experience__features {
    margin: 2rem 0;
    grid-gap: 1rem;
    gap: 1rem;
  }
  .explain-product p {
   max-width: 325px;
  }
  .text {
    font-size: 2.5rem;
  }
  .text-small {
    font-size: 1.6rem;    
  }
  .star-square{
    display: flex;
  }
  .experience__col h3 {
    font-size: 2rem;
   
}
  }
  @media (width < 350px) {
    .explain-product p {
      max-width: 225px;
     }
  } 
  @media (width < 800px) {
    .star-square {
      display: flex;
    
   }
  } 