.Service_section {
    max-width: 1000px;
    margin: auto;
    padding: 1rem 1rem;
    max-height: 600px;
  }
  .Service-product p{
    font-size: 12px;
    color: black ;
    margin: 5px;
    max-width: 800px;
  }
  .final p{
    font-size: 16px;
    color: black ;
    margin-top: 35px;
  }
  .Service-square {
    width:200px;
    height: 150px;
    margin: 20px;
    display: flex;
  } 
  .Service-square img{
    width:200px;
    height: 125px;
    margin: 20px;

  } 
 .section_choice{
  width: 100%;
  background-color: #181818;
  height: 400px;
 }
 .section_choice-firm{
  width: 100%;
  background-color: #181818;
  height: 700px;
 }
 .section__header-ch{
  font-size: 1.5rem;
    font-weight: 500;
    line-height: 2rem;
    margin-bottom: 1rem;
    color: white;
  margin-top: 30px;
 }
  .choice{
    letter-spacing: 1px;
    margin: 0px 0px;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
  }
  .choice-firm{
    letter-spacing: 1px;
    margin: 0px 0px;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
  }
  .my_choice{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    gap: 4rem;
    margin-top: 50px;
    
  }
  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #111; /* Your background color */
    width: 100px; /* Adjust width and height as needed */
    height: 100px;
    border-radius: 5px; /* Makes it a circle if needed */
    margin-left: 15px;
  }
  .icon-container span{
    color: #3498db;
  }
  .icon p{
    margin-top: 1rem;
  font-size: 1.3rem;
  color: #ffffff;
  }
  .boxer img{
    width: 150px;
    height: 150px;
  }
  .my_choice-more p{
    color: #ffffff;
  }
  @media (width < 450px) {
    
  }

  .sect{
    width: 100%;
    background-color: #181818;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sect-c{
  
    background-color: #111;
    width: 350px;
    height: 400px;
    border-radius: 7px;
    
  }
  .image span{
    
    margin-left: 140px;
    color: #1ca2e3 ;
  }
  .image{
    margin-top: 30px;
  }
  .sect-c h3{
    color: #ffffff;
    font-size: 1.5rem;
    margin-left: 100px;
  }
  .sect-c p{
    color: #ffffff;
    font-size: 0.9rem;
    margin: 20px;
  }
  a {
    text-decoration: none;
    color: #1ca2e3 ;
}
  .ss p{
    margin-left: 50px;
    color: #ffffff;
    font-size: 1rem;
  }