@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap);
@import url(https://cdn.syncfusion.com/ej2/material.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family:  'Poppins', sans-serif;
}
.footer{
    position: relative;
    width: 100%;
    height: auto;
    padding: 35px 35px;
    background-color: #333;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
.container{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
}
.sec{
    margin-right: 20px;
}
.sec.about-us{
    width: 30%;
}
.sec h2{
    position: relative;
    color: #fff;
    font-weight: 500;
    margin-bottom: 15px;
    font-size: 19px;
}
.sec h2:before{
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 50px;
    height: 2px;
    background-color:#1ca2e3;
}
 p{
 color: #999;
 font-size: 1rem;

}
.socials{
    margin-top: 20px;  
}
.socials li{
    list-style: none;
}
.socials li a {
    display: inline-block;
    align-content: center;
    /* width: 35px;
    height: 35px;
    background: #222; */
    margin-right: 10px;
    border-radius: 50px;
    text-decoration: none;
}
.sec.worktime{
    width: 30%;
}
.sec.worktime h2{
    margin-bottom: 20px;
}
.sec.worktime.final p{
    margin-top: 10px;
    color: #fff;
}
.copyright{
    width: 100%;
    background-color: #333;
    padding: 8px 100px;
    text-align: center;
}
.dis35 {
    height: 35px;
    clear: both;
    width: 100%;
}
hr {
    margin: 0;
    border-color: #444;
}
@media(max-width:991px){
    .container{
        flex-direction: column;
        margin-right: 20px;
    }
    .sec{
        margin-bottom: 20px;
    }
    .sec.about-us{
        width: 100%;
    }
    .sec.worktime{
        width: 100%;
    }
}
@media(max-width:491px){
    .footer{
        padding-right: 2px;
        padding-left: 2px;;
    }
    .container{
        width: 100%;
    }
    .sec{
        margin-right: 5px;
    }
    p{
        font-size: 16px;
        margin-left: 5px;
    }
    .sec.about-us{
        width: 250px;
    }
    .sec.worktime{
        width: 250px;
    }
}
@media(max-width:280px){
    p{
        font-size: 12px;
        margin-left: 5px;
    }
    .sec.about-us{
        width: 200px;
    }
    .sec.worktime{
        width: 200px;
    }
}
:root {
  --primary-color: #1371C3;
  --text-dark: #242424;
  --text-light: #e2e8f0;
  --white: #ffffff;
  --max-width: 1200px;
}
body{
  overflow-x: hidden;
}
/*header*/
header{
  background: url(/static/media/wallpaper.a8407bba.jpg);
  height: 650px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.section{
  max-width: 100%;
  margin: auto;
  padding: 1rem 1rem;
  height:600px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
.text{
  font-size: 4rem;
  text-align: center;
  margin-top: 1rem;
  color: #ffffff;
}
.text-small{
  font-size: 2rem;
  text-align: center;
  margin-top: 0.5rem;
  color: #ffffff;
}
.left{
  position: relative;
}
.right{
  height: 100%;
  position: relative;
  margin-left: 50px;
}

.img1{
  width:250px;
  position: absolute;
  top:50px;
}
.img2{
  width: 280px;
  position: absolute;
  top:150px;
  left:100px;
  height: 280px;
}
.explain-product{
  width: 500px;
  margin:auto;
  padding-top:1rem;
}
.explain-product p{
  text-align: justify;
  color: #ffffff;
  font-size: 1rem;
}
.learn{
  margin-top: 20px;
}
.learn a {
  color: #1ca2e3;
  text-decoration: none;
  position: relative;
}
.learn a::before{
  content: "";
  position: absolute;
  bottom:  -2px;
  height: 2px;
  width: 100%;
  background: #1ca2e3;
  border-radius: 50px;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  transition: -webkit-transform 0.2 linear;
  transition: transform 0.2 linear;
  transition: transform 0.2 linear, -webkit-transform 0.2 linear;

}
.learn a:hover::before{
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}
.star-square{
  display: none;
  position: absolute;
  bottom:40px;
  padding-top:1rem;
}
.star-square img{
  width:80px;
  margin: 10px;
}

.button{
  width:80%;
  margin-left: auto;
  margin-top: 1rem;
}
.btn-order{
  background-color: #1ca2e3; /* Green */
  border: none;
  color: white;
  padding: 10px 19px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
  cursor:pointer;
}
.section__container {
    max-width: 1200px;
    max-width: var(--max-width);
    margin: auto;
    padding: 3rem 1.5rem;
  }
  
  .section__header {
    font-weight: 600;
    line-height: 2rem;
    margin-bottom: 2rem;
    font-size: 2.7em;
    font-family: 'myriad-bold';
    color: #474646;
  }
  
  strong{
    color:  #1371C3;
    color:  var(--primary-color);
    margin-bottom: 2rem;
  }
  img {
    width: 100%;
  }
  
  a {
    text-decoration: none;
  }
  
  body {
    font-family: "Poppins", sans-serif;
  }
  .benefits__container {
   max-width: 100%;
   background-color: #ffffff;
  }
  /* .benefits__card:hover {
    margin-bottom: 10px;
   } */
    
  .benefits__container .section__header {
    max-width: 400px;
    margin-bottom: 2rem;
    margin-left: 5rem;
    position: relative;
  }
  
  .benefits__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
    gap: 2rem;
    border: 2px solid transparent;
  }
  
  .benefits__card {
    padding: 1rem;
    display: block;
    margin-left: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 15%);
  }
  
  .benefits__card span img{
    width: 60px;
    height: 60px;
    border-radius: 100%;
    margin-left: 40px;
    margin-bottom: 10px;
  }
.benefits__card span{
  color: #2d9bea;
  margin-left: 80px;
}
  .benefits__card h4 {
    font-size: 1rem;
    font-weight: 600;
    color: #242424;
    color: var(--text-dark);
    margin-bottom: 20px;
    margin-left: 50px;
  }
  
  .benefits__content p {
    font-size: 0.9rem;
    line-height: 1.75rem;
    color: #000;
  }
  
  .experience__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    gap: 2rem;
  }
  .experience__description p {
    margin-bottom: 0.8rem;
    font-size: 0.9rem;
    color: #000;
  }
  .experience__image img {
    max-width: 400px;
    height: 400px;
    object-fit: cover;
    border-radius: 5px;
    margin: 50px;
  
  }
  .experience__images img {
    max-width: 400px;
    height: 400px;
    object-fit: cover cover;
    border-radius: 5px;
    margin: 50px;
  
  }
  
  .experience__description {
    margin-top: 0.9 rem;
  font-size: 0.9rem;
  line-height: 1.75rem;
  color: #000;
  
  }
  
  .experience__features {
    margin: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 3rem;
    gap: 3rem;
  }
  
  .experience__col h3 {
    font-size: 3rem;
    font-weight: 600;
    color: #1371C3;
    color: var(--primary-color);
  }
  
  .experience__col p {
    font-size: 1rem;
    font-weight: 500;
    color: #000;
    
  }
  
  .experience__content button {
    padding: 0.75rem 1.5rem;
    font-size: 0.9rem;
    font-weight: 600;
    color: #1371C3;
    color: var(--primary-color);
    outline: none;
    border: 2px solid #1371C3;
    border: 2px solid var(--primary-color);
    background-color: #ffffff;
    background-color: var(--white);
    cursor: pointer;
    transition: 0.3s;
  }
  
  .experience__content button:hover {
    color: #ffffff;
    color: var(--white);
    background-color: #1371C3;
    background-color: var(--primary-color);
  }
  
  .process__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    gap: 2rem;
  }
  
  .process__grid {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 50px auto;
    grid-column-gap: 1.5rem;
    -webkit-column-gap: 1.5rem;
            column-gap: 1.5rem;
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem;
    align-items: center;
    
    
  }
  
  .process__grid .number {
    height: 50px;
    width: 50px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: 600;
    color: #ffffff;
    color: var(--white);
    background-color: #1371C3;
    background-color: var(--primary-color);
  }
  
  .process__grid h4 {
    font-size: 1.2rem;
    font-weight: 600;
    color: #474646;
  }
  
  .process__grid p {
    margin-top: 0.5rem;
    font-size: 0.9rem;
    color: #000;
  }
  
  .process__grid span:not(.last-child) {
    height: 100%;
    width: 2px;
    margin: auto;
    background-color: #1371C3;
    background-color: var(--primary-color);
  }
  
  .process__image {
    text-align: right;
  }
  
  .process__image img {
    max-width: 400px;
    height: 400px;
    border-radius: 5px;
    margin-top: 50px;
  }
  
  .customer__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
  }
  
  .customer__nav .section__header {
    max-width: 400px;
  }
  
  .customer__nav__icons {
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
  }
  
  .customer__nav__icons span {
    font-size: 1.5rem;
    color: #1371C3;
    color: var(--primary-color);
    cursor: pointer;
  }
  
  .customer__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    gap: 2rem;
  }
  
  .customer__card {
    padding: 2rem;
    display: grid;
    place-content: baseline;
    grid-gap: 1rem;
    gap: 1rem;
    color: #ffffff;
    color: var(--white);
    border-radius: 5px;
    background-color: #1371C3;
    background-color: var(--primary-color);;
  }
  
  .customer__card span i {
    font-size: 4rem;
  }
  
  .customer__title {
    font-size: 1.5rem;
    font-weight: 500;
  }
  
  .customer__description {
    margin-bottom: 1rem;
    font-size: 1rem;
    color: #ffffff;
  }
  
  .customer__content {
    display: flex;
    align-items: center;
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }
  
  .customer__content span img{
    display: flex;
    max-width: 50px;
    height: 50px;
    border-radius: 100%;

  }
  
  .customer__details h4 {
    font-size: 1rem;
    font-weight: 500;
  }
  
  .customer__details p {
    font-size: 0.9rem;
    color: #e2e8f0;
    color: var(--text-light);
  }
  .all-review{
    width: 25vw;
    margin-top: 30px;
  }
  
  .image_wrapper {
    margin: 0px 30px;
  }

  .carousel{
    width: 60vw;
    padding: 1.5rem;
    background-color: #fff;
    color: #fff;
    margin-top: 50px;
  }
  .carousel .box{
   height: 150px;
   background-color: transparent;
  
  }
  .slick-slide div{
    margin: 0 1rem;
  }
  .slick-dots li button {
   
    width: 50px;
    height: 50px;
    padding: 5px;
    cursor: pointer;
    color: #fff  !important;
    background: transparent;
}
  .slick-dots li.slick-active button:before {
    opacity: 1;
    color: #1ca2e3 !important;
}
.slick-dots li button:before {
 color: #1371C3 !important;
 color: var(--primary-color) !important;
 font-size: 1rem !important;
 top: 0.5rem !important;
}
  .image_wrapper img {
    width: 80%;
  }

.slick-next {
   
    width: 40px !important;
    height: 40px !important;
    cursor: pointer;
    background: #1371C3 !important;
    background: var(--primary-color) !important;
}
.slick-prev{
    width: 40px !important;
    height: 40px !important;
    cursor: pointer;
    background: #1371C3 !important;
    background: var(--primary-color) !important;
}




  @media (max-width: 999px) {
    .benefits__grid {
      grid-gap: 1rem;
      gap: 1rem;
    }
    .right{
      display: none;
    }
    .benefits__card {
      flex-direction: column;
    }
  
    .customer__grid {
      grid-gap: 1rem;
      gap: 1rem;
    }
  
    .footer__container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 924px) {
    .nav__links {
      display: none;
    }
    .text{
      font-size: 3rem;
    }
  
    .header__ribbon {
      background-color: rgba(92, 108, 121, 0.7);
    }
    .right{
      display: none;
    }
    .arrow__down {
      display: none;
    }
  
    .benefits__container .section__header {
      margin-left: 1rem;
    }
  
    .benefits__grid {
      grid-template-columns: repeat(1, 1fr);
    }
  
    .benefits__card {
      flex-direction: row;
    }
  
    .experience__container {
      grid-template-columns: repeat(1, 1fr);
      margin: 5px;
    }
  
    .process__container {
      grid-template-columns: repeat(1, 1fr);
    }
  
    .process__image {
      grid-area: 1/1/2/2;
    }
  
    .customer__grid {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 1rem;
      gap: 1rem;
    }
  
    .footer__container {
      grid-gap: 2rem;
      gap: 2rem;
    }
  }

  
  @media (max-width: 449px) {
    .experience__image img {
      max-width: 300px;
      margin: 5px;
  }
  .experience__images img {
    max-width: 300px;
    margin: 5px;
}
  .experience__description {
   max-width: 350px;
   margin-right: 20px;
   font-size: 0.9rem;
  }
  .all-review {
    width: 60vw;
    margin-top: 30px;
}
  .experience__features {
    margin: 2rem 0;
    grid-gap: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
  }
  .explain-product p {
   max-width: 325px;
  }
  .text {
    font-size: 2.5rem;
  }
  .text-small {
    font-size: 1.6rem;    
  }
  .star-square{
    display: flex;
  }
  .experience__col h3 {
    font-size: 2rem;
   
}
  }
  @media (max-width: 349px) {
    .explain-product p {
      max-width: 225px;
     }
  } 
  @media (max-width: 799px) {
    .star-square {
      display: flex;
    
   }
  } 
root {
    --background: #f2f5f7;
    --text-primary: #2f4770;
    --text-secondary: #647780;
    --white: #ffffff;
}


.container{
  max-width: 1140px;
  margin: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* accordion styles */

h1 {
  font-size: 42px;
  color: #2f4770;
  margin: 0px;
  margin-bottom: 30px;
}

.accordion-simple > .active {
  display: block;
}

.accordion__faq .inactive{
  display: none;
}

.accordion__faq > div{
  margin-bottom: 20px;
  padding: 0px 15px;
  border-radius: 5px;
  cursor: pointer;
  border-bottom: 1px solid #474646;
}

.accordion__title{
  color: #295373;
  padding-bottom: 20px;
  font-weight: 500;
}

.accordion__faq-heading{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.accordion__faq-heading .active{
  color: #2f4770;
}

.accordion__faq-heading h3{
  color: rgb(27, 26, 26) ;
  font-weight: 600;
}

.accordion__faq p {
  padding-bottom: 30px;
  line-height: 1.4;
  font-size: 12px;
  color: black ;
  margin: 5px;
}


.hommie {
  max-width: 1200px;
  margin: auto;
  padding: 5rem 1rem;
}
.service{
  display: flex;
  flex-wrap: wrap;
}
.process{
  display: flex;
  flex-wrap: wrap;
}

.fate{
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 15%);
}
@media (max-width: 1049px) {
  .process__content-p{
    width: 100%;
  }
  .fate{
    width: 100%;
    
}
}

.Service_section {
    max-width: 1000px;
    margin: auto;
    padding: 1rem 1rem;
    max-height: 600px;
  }
  .Service-product p{
    font-size: 12px;
    color: black ;
    margin: 5px;
    max-width: 800px;
  }
  .final p{
    font-size: 16px;
    color: black ;
    margin-top: 35px;
  }
  .Service-square {
    width:200px;
    height: 150px;
    margin: 20px;
    display: flex;
  } 
  .Service-square img{
    width:200px;
    height: 125px;
    margin: 20px;

  } 
 .section_choice{
  width: 100%;
  background-color: #181818;
  height: 400px;
 }
 .section_choice-firm{
  width: 100%;
  background-color: #181818;
  height: 700px;
 }
 .section__header-ch{
  font-size: 1.5rem;
    font-weight: 500;
    line-height: 2rem;
    margin-bottom: 1rem;
    color: white;
  margin-top: 30px;
 }
  .choice{
    letter-spacing: 1px;
    margin: 0px 0px;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
  }
  .choice-firm{
    letter-spacing: 1px;
    margin: 0px 0px;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
  }
  .my_choice{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    grid-gap: 4rem;
    gap: 4rem;
    margin-top: 50px;
    
  }
  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #111; /* Your background color */
    width: 100px; /* Adjust width and height as needed */
    height: 100px;
    border-radius: 5px; /* Makes it a circle if needed */
    margin-left: 15px;
  }
  .icon-container span{
    color: #3498db;
  }
  .icon p{
    margin-top: 1rem;
  font-size: 1.3rem;
  color: #ffffff;
  }
  .boxer img{
    width: 150px;
    height: 150px;
  }
  .my_choice-more p{
    color: #ffffff;
  }
  @media (max-width: 449px) {
    
  }

  .sect{
    width: 100%;
    background-color: #181818;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sect-c{
  
    background-color: #111;
    width: 350px;
    height: 400px;
    border-radius: 7px;
    
  }
  .image span{
    
    margin-left: 140px;
    color: #1ca2e3 ;
  }
  .image{
    margin-top: 30px;
  }
  .sect-c h3{
    color: #ffffff;
    font-size: 1.5rem;
    margin-left: 100px;
  }
  .sect-c p{
    color: #ffffff;
    font-size: 0.9rem;
    margin: 20px;
  }
  a {
    text-decoration: none;
    color: #1ca2e3 ;
}
  .ss p{
    margin-left: 50px;
    color: #ffffff;
    font-size: 1rem;
  }
/* LoadingAnimation.css */

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
  }
  
  .loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #3498db; /* Change the color as desired */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    -webkit-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite;
    margin-top: 20px;
  }
  .title{
    color: #ffffff;
    margin-top: 20px;
  }
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  p{
    color: #ffffff;
  }
.section_choices{
    width: 100%;
    background-color: #181818;
    height: auto;
}
.form{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: auto;
}
.form-container{
    letter-spacing: 1px;
    margin: 30px 0px;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.form-container header h2{
    position: relative;
    padding: 10px 20px;
    color: #09bef0;
    font-weight: 500;
    margin-bottom: 5px;
    font-size: 30px;
}
.office{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: auto;
    background-color: #121b22;
}
.office-containers{
    letter-spacing: 1px;
    margin: 30px 0px;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
form{
    width: auto;
    display: block;
    margin-left: 3px;
}
.ui-message-success{
    font-size: 20px;
    color: #ffffff;
}
form input{
    width: 250px;
    height: 30px;
    margin-bottom: 5px;
    border-radius: 6px;
    border-color: #1ca2e3;
}
form select{
    width: 250px;
    height: 30px;
    margin-bottom: 5px;
    border-radius: 6px;
    border-color: #1ca2e3; 
}
.icon label p{
    font-size: 13px;
    margin-left: 30px;
    color: #ffffff;
    margin-bottom: 5px;
    margin-top: 15px;
    font-weight: 500;
}
.icon p{
    margin-top: 0.1rem;
    font-size: 0.7rem;
    color: #ed5959;
}
.too span{
    display: flex;
}
.icon_move{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.icon_move label{
    font-size: 13px;
    color: #ffffff;
    margin-bottom: 3px;
    margin-top: 15px;
    font-weight: 500;
}
.icon2 p{
    font-size: 15px;
    color: #ffffff;
    margin-left: 10px;
    margin-top: 15px;
    font-weight: 500; 
}
.error{
    font-size: 11px;
    color: #f21010;
    margin-bottom: 3px;
    margin-top: 2px;
    font-weight: 500; 
}
form p{
    font-size: 10px;
    margin-left: 30px;
    color: #ffffff;
    margin-bottom: 5px;
    font-weight: 500;
}
form button{
    background-color: #1ca2e3; /* Green */
    border: none;
    color: white;
    padding: 10px 52px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-left: 50px;
    border-radius: 5px;
}
.more{
    margin-top: 5px;
}

.ui-message-success-2{
    color:  #1ca2e3;
}
.grid{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    grid-gap: 4rem;
    grid-gap: 0.7rem  ;
    gap: 0.7rem  ; 
    margin-top: 20px;
}
@media (max-width: 449px) {
    .grid{
        display: flex;
        flex-direction: column;
        grid-gap: 0.8rem;
        margin-left: 40px;
    }
    .my_choice-more{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2rem;
        grid-gap: 1rem;
        grid-gap: 0.7rem  ;
        gap: 0.7rem  ;
        margin-top: 20px;
    }
    .section_choice {
        height: auto;
    }
    form button {
        padding: 6px 16px;
        font-size: 16px;
        margin-bottom: 20px;
        border-radius: 5px;
    }
  }
  
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  .navbar {
    background-color: #fcfcfc;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.0rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999;
    box-shadow: 0 3px 6px rgb(0 0 0 / 15%);
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    height: 80px;
  }
  
  .container {
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 50px;
    padding-left: 50px;
    align-items: center;
  }
  
  .navbar-logo {
    color: #080808;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    width: 200px;
    height: 40px;
    margin: 10px;
  }
  
  .navbar-icon {
    margin-right: 0.5rem;
  }
  
  .nav-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    text-align: center;
    justify-content: flex-end;
  }
  
  .nav-item {
    height: 60px;
    border-bottom: 2px solid transparent;
  }
  
  .nav-item:hover {
    border-bottom: 2px solid #09bef0;
  }
  
  .nav-links {
    color: #080808;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    font-size: 1rem;
  }
  
  .activated {
    color: #09bef0;
  }
  
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 1060px) {
    .NavbarItems {
      position: relative;
    }
  .navbar{
    background-color: #fcfcfc;
    height: 100px;
  }
  .img-wrapper{
    display: none;
  }
  .phone-wrapper{
    display: none;
  }
  .numbers {
   opacity: 0;
  }
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #19191a;
      left: 0;
      opacity: 1;
      transition: all 0.6s ease;
      z-index: 1;
      height: 500px;
      margin-top: 20px;
      
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
      color: #ffffff;
      height: 600px;
    }
  
    .nav-links:hover {
      color: #09bef0;
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
      transition: all 0.3s ease;
    }
  
    .nav-item:hover {
      border: none;
    }
  
    .nav-item {
      width: 100%;
      height: 100px;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      -webkit-transform: translate(25%, 50%);
              transform: translate(25%, 50%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      -webkit-transform: translate(-100%, 60%);
              transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
      color: #090909 !important;
    }
    .menu-icon span i{
      color: #090909 !important;
    }
    
  }
.img-wrapper {
    background: #001e36;
    padding: 0.3em 0.4em;
    border-bottom-left-radius: 7px;
    border-top-left-radius: 7px;
}
.phone-wrapper {
  padding: 0.4em 0.5em;
  font-size: 0.9rem;
  color: #090909;
}
.numbers{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.9em;
    text-decoration: none;
    border: 4px solid #001e36;
    border-radius: 10px;
    color: #001e36;
    height: 33px;
    margin-top: 10px;
}
