@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family:  'Poppins', sans-serif;
}
.footer{
    position: relative;
    width: 100%;
    height: auto;
    padding: 35px 35px;
    background-color: #333;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
.container{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
}
.sec{
    margin-right: 20px;
}
.sec.about-us{
    width: 30%;
}
.sec h2{
    position: relative;
    color: #fff;
    font-weight: 500;
    margin-bottom: 15px;
    font-size: 19px;
}
.sec h2:before{
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 50px;
    height: 2px;
    background-color:#1ca2e3;
}
 p{
 color: #999;
 font-size: 1rem;

}
.socials{
    margin-top: 20px;  
}
.socials li{
    list-style: none;
}
.socials li a {
    display: inline-block;
    align-content: center;
    /* width: 35px;
    height: 35px;
    background: #222; */
    margin-right: 10px;
    border-radius: 50px;
    text-decoration: none;
}
.sec.worktime{
    width: 30%;
}
.sec.worktime h2{
    margin-bottom: 20px;
}
.sec.worktime.final p{
    margin-top: 10px;
    color: #fff;
}
.copyright{
    width: 100%;
    background-color: #333;
    padding: 8px 100px;
    text-align: center;
}
.dis35 {
    height: 35px;
    clear: both;
    width: 100%;
}
hr {
    margin: 0;
    border-color: #444;
}
@media(max-width:991px){
    .container{
        flex-direction: column;
        margin-right: 20px;
    }
    .sec{
        margin-bottom: 20px;
    }
    .sec.about-us{
        width: 100%;
    }
    .sec.worktime{
        width: 100%;
    }
}
@media(max-width:491px){
    .footer{
        padding-right: 2px;
        padding-left: 2px;;
    }
    .container{
        width: 100%;
    }
    .sec{
        margin-right: 5px;
    }
    p{
        font-size: 16px;
        margin-left: 5px;
    }
    .sec.about-us{
        width: 250px;
    }
    .sec.worktime{
        width: 250px;
    }
}
@media(max-width:280px){
    p{
        font-size: 12px;
        margin-left: 5px;
    }
    .sec.about-us{
        width: 200px;
    }
    .sec.worktime{
        width: 200px;
    }
}