root {
    --background: #f2f5f7;
    --text-primary: #2f4770;
    --text-secondary: #647780;
    --white: #ffffff;
}


.container{
  max-width: 1140px;
  margin: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* accordion styles */

h1 {
  font-size: 42px;
  color: #2f4770;
  margin: 0px;
  margin-bottom: 30px;
}

.accordion-simple > .active {
  display: block;
}

.accordion__faq .inactive{
  display: none;
}

.accordion__faq > div{
  margin-bottom: 20px;
  padding: 0px 15px;
  border-radius: 5px;
  cursor: pointer;
  border-bottom: 1px solid #474646;
}

.accordion__title{
  color: #295373;
  padding-bottom: 20px;
  font-weight: 500;
}

.accordion__faq-heading{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.accordion__faq-heading .active{
  color: #2f4770;
}

.accordion__faq-heading h3{
  color: rgb(27, 26, 26) ;
  font-weight: 600;
}

.accordion__faq p {
  padding-bottom: 30px;
  line-height: 1.4;
  font-size: 12px;
  color: black ;
  margin: 5px;
}


.hommie {
  max-width: 1200px;
  margin: auto;
  padding: 5rem 1rem;
}
.service{
  display: flex;
  flex-wrap: wrap;
}
.process{
  display: flex;
  flex-wrap: wrap;
}

.fate{
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 15%);
}
@media (width < 1050px) {
  .process__content-p{
    width: 100%;
  }
  .fate{
    width: 100%;
    
}
}
