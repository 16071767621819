* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  .navbar {
    background-color: #fcfcfc;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.0rem;
    position: sticky;
    top: 0;
    z-index: 999;
    box-shadow: 0 3px 6px rgb(0 0 0 / 15%);
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    height: 80px;
  }
  
  .container {
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 50px;
    padding-left: 50px;
    align-items: center;
  }
  
  .navbar-logo {
    color: #080808;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    width: 200px;
    height: 40px;
    margin: 10px;
  }
  
  .navbar-icon {
    margin-right: 0.5rem;
  }
  
  .nav-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    text-align: center;
    justify-content: flex-end;
  }
  
  .nav-item {
    height: 60px;
    border-bottom: 2px solid transparent;
  }
  
  .nav-item:hover {
    border-bottom: 2px solid #09bef0;
  }
  
  .nav-links {
    color: #080808;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    font-size: 1rem;
  }
  
  .activated {
    color: #09bef0;
  }
  
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 1060px) {
    .NavbarItems {
      position: relative;
    }
  .navbar{
    background-color: #fcfcfc;
    height: 100px;
  }
  .img-wrapper{
    display: none;
  }
  .phone-wrapper{
    display: none;
  }
  .numbers {
   opacity: 0;
  }
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #19191a;
      left: 0;
      opacity: 1;
      transition: all 0.6s ease;
      z-index: 1;
      height: 500px;
      margin-top: 20px;
      
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
      color: #ffffff;
      height: 600px;
    }
  
    .nav-links:hover {
      color: #09bef0;
      transform: scale(1.2);
      transition: all 0.3s ease;
    }
  
    .nav-item:hover {
      border: none;
    }
  
    .nav-item {
      width: 100%;
      height: 100px;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
      color: #090909 !important;
    }
    .menu-icon span i{
      color: #090909 !important;
    }
    
  }
.img-wrapper {
    background: #001e36;
    padding: 0.3em 0.4em;
    border-bottom-left-radius: 7px;
    border-top-left-radius: 7px;
}
.phone-wrapper {
  padding: 0.4em 0.5em;
  font-size: 0.9rem;
  color: #090909;
}
.numbers{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.9em;
    text-decoration: none;
    border: 4px solid #001e36;
    border-radius: 10px;
    color: #001e36;
    height: 33px;
    margin-top: 10px;
}