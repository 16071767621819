/* LoadingAnimation.css */

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
  }
  
  .loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #3498db; /* Change the color as desired */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin-top: 20px;
  }
  .title{
    color: #ffffff;
    margin-top: 20px;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  p{
    color: #ffffff;
  }